export function useRatings() {
  const { t } = useI18n();

  const ratingClasses = {
    NEW: 'NEW',
    NORATING: 'NORATING',
    RATING: 'RATING',
    GOOD: 'GOOD',
    VERYGOOD: 'VERYGOOD',
    FABULOUS: 'FABULOUS',
    SUPERB: 'SUPERB',
  };

  const ratingScoresTranslations = {
    tNew: t('t_RATINGNEW'),
    tReviews: t('t_RATINGREVIEWS'),
    tFabulous: t('t_RATINGFABULOUS'),
    tSuperb: t('t_RATINGSUPERB'),
    tVerygood: t('t_RATINGVERYGOOD'),
    tGood: t('t_RATINGGOOD'),
    tActivities: t('t_RATINGACTIVITIES'),
    tEatingOut: t('t_RATINGEATINGOUT'),
    tShopping: t('t_RATINGSHOPPING'),
    tRelaxing: t('t_RATINGRELAXING'),
    tTransport: t('t_RATINGTRANSPORT'),
    tSightSeeing: t('t_RATINGSIGHTSEEING'),
    tCulture: t('t_RATINGCULTURE'),
    tNightlife: t('t_RATINGNIGHTLIFE'),
    tValue: t('t_RATINGVALUE'),
    tSecurity: t('t_RATINGSECURITY'),
    tLocation: t('t_RATINGLOCATION'),
    tStaff: t('t_RATINGSTAFF'),
    tAtmosphere: t('t_RATINGATMOSPHERE'),
    tCleanliness: t('t_RATINGCLEANLINESS'),
    tValueForMoney: t('t_RATINGVALUE'),
    tFacilities: t('t_RATINGFACILITIES'),
  };

  const getRatingClass = (rating, isNew) => {
    if (isNew) {
      return ratingClasses.NEW;
    }

    if (rating === 0) {
      return ratingClasses.NORATING;
    }

    if (rating >= 9) {
      return ratingClasses.SUPERB;
    }

    if (rating >= 8) {
      return ratingClasses.FABULOUS;
    }

    if (rating >= 7) {
      return ratingClasses.VERYGOOD;
    }

    if (rating >= 6) {
      return ratingClasses.GOOD;
    }

    return ratingClasses.RATING;
  };

  const getRatingBreakdown = (propertyRating) => {
    return {
      ratings: {
        ...{
          valueForMoney: propertyRating?.breakdown?.valueForMoney || 0,
          security: propertyRating?.breakdown?.security || 0,
          atmosphere: propertyRating?.breakdown?.atmosphere || 0,
          cleanliness: propertyRating?.breakdown?.cleanliness || 0,
          staff: propertyRating?.breakdown?.staff || 0,
          location: propertyRating?.breakdown?.location || 0,
          facilities: propertyRating?.breakdown?.facilities || 0,
        },
        averageRating: propertyRating?.averageRating,
        numberOfReviews: propertyRating?.numberOfReviews,
      },
    };
  };

  const getRatingScoreKeywordTranslations = computed(() => ratingScoresTranslations);

  return {
    useRating: {
      getRatingClass,
      getRatingScoreKeywordTranslations,
      getRatingBreakdown,
    },
  };
}
